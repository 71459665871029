:host {
  display: block;
  min-width: 0;
  width: 100%;
}

sc-choice-container {
  container-type: inline-size;
}

.price-choice  {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: var(--sc-line-height-dense);
  gap: var(--sc-spacing-small);

  &__name {
    color: var(--sc-price-choice-name-color, var(--sc-input-label-color));
    font-size: var(--sc-price-choice-name-size, var(--sc-input-label-font-size-medium));
    font-weight: var(--sc-price-choice-name-font-weight, var(--sc-font-weight-bold));
    text-transform: var(--sc-price-choice-text-transform, var(--sc-input-label-text-transform, none));
    display: -webkit-box;
    display: -moz-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-line-clamp: 3;
    -moz-box-lines: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__description {
    color: var(--sc-input-help-text-color);
  }

  > *:not(:first-child):last-child {
    text-align: right;
  }

  &__details {
    flex: 1 0 50%;
    display: grid;
    gap: var(--sc-spacing-xxx-small);
  }

  &__trial,
  &__setup-fee,
  &__price {
    font-size: var(--sc-font-size-small);
    color: var(--sc-input-help-text-color);
  }

  &__price {
    color: var(--sc-input-label-color);
    font-weight: var(--sc-price-choice-price-font-weight, var(--sc-font-weight-normal));
  }
}

@container (max-width: 325px) {
  .price-choice {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--sc-spacing-xx-small);
    > *:not(:first-child):last-child {
      text-align: initial;
    }
  }
}
